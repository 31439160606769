import { faMapMarkerAlt, faRuler, faShareAlt, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase';
import React from 'react';
import { PointOfSale } from './schema';
import SellableItem from './SellableItem';

export default class PointOfSaleView extends React.Component<{ pointOfSale: PointOfSale }> {
    logClick = (target: string) => {
        firebase.analytics().logEvent('click', {
            "pointOfSaleId": this.props.pointOfSale.id,
            "target": target,
        })
    }
    share = () => {
        if (navigator.share) {
            navigator.share({
                title: "GlassBox",
                text: "Check out GlassBox",
                url: "https://glassbox.one/",
            });
        }
    }
    render() {
        const pointOfSale = this.props.pointOfSale;
        const distance = (pointOfSale.distanceMeters !== undefined)
            ? ((pointOfSale.distanceMeters < 1000)
                ? `${pointOfSale.distanceMeters.toFixed()}m`
                : `${(pointOfSale.distanceMeters / 1000).toFixed()}km`)
            : `---`;
        const mapsUrl = `https://www.google.co.uk/maps/place/${pointOfSale.position.address}`;
        return (
            <article className="my-4 border-2 border-primary-500 rounded-md divide-y divide-primary-500">
                <div className="p-4 sticky top-0 bg-white rounded-t-md z-10 border-b border-primary-500 bg-primary-100">
                    <div onClick={(_) => this.logClick('point_of_sale_name')}>
                        <FontAwesomeIcon className="mr-1" size="1x" fixedWidth icon={faShoppingCart} />
                        {pointOfSale.name}
                    </div>
                    <div onClick={(_) => this.logClick('address')}>
                        <a href={mapsUrl}
                           target="_blank"
                           rel="noreferrer"
                        >
                            <FontAwesomeIcon className="mr-1" size="1x" fixedWidth icon={faMapMarkerAlt} />
                            {pointOfSale.position.address || "---"}
                        </a>
                    </div>
                    <div onClick={(_) => this.logClick('distance')}>
                        <FontAwesomeIcon className="mr-1" size="1x" fixedWidth icon={faRuler} />
                        {distance}
                    </div>
                    <div onClick={(_) => this.share()}>
                        <FontAwesomeIcon className="mr-1" size="1x" fixedWidth icon={faShareAlt} />
                        share
                    </div>
                </div>
                <div className="divide-y divide-primary-500 w-full">
                    {pointOfSale.items.map((item) => <SellableItem key={item.name} item={item} pointOfSale={pointOfSale} />)}
                </div>
            </article>
        );
    }
}
