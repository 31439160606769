import firebase from 'firebase';
import { debounce } from 'throttle-debounce';
import React, { ChangeEvent, ChangeEventHandler } from 'react';
import './App.css';
import { Item, PointOfSale } from './schema';
import SellableItem from './SellableItem';
import haversine from 'haversine';
import PointOfSaleView from './PointOfSale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faEnvelope, faLocationArrow, faMailBulk, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

interface State {
  error?: string,
  isLoaded: boolean,
  pointOfSales: PointOfSale[],
  currentPosition?: GeolocationPosition,
  searchText: string,
}

class App extends React.Component<unknown, State> {
  searchInput?: HTMLInputElement;

  constructor(props: unknown) {
    super(props);
    this.state = {
      isLoaded: false,
      pointOfSales: [],
      searchText: ""
    };
  }

  componentDidMount() {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyDhZL2dOZ53uFwxpKkwtS_g-3bt7ZdR_Ug",
      authDomain: "vending-bbb45.firebaseapp.com",
      projectId: "vending-bbb45",
      storageBucket: "vending-bbb45.appspot.com",
      messagingSenderId: "352484479276",
      appId: "1:352484479276:web:0b15ce8d13b6baee219c89",
      measurementId: "G-2L37CSWXVB"
    };
    // Initialize Firebase
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }
    firebase.analytics();

    this.tryGetLocation();

    const pointOfSales: Array<PointOfSale> = [
      {
        id: "pos2",
        name: "H24",
        position: {
          latitude: 42.4045,
          longitude: 12.8567,
          address: "Via Roma, 28, 02100 Rieti RI, Italy"
        },
        items: [
          {
            price: "€2.20",
            name: "Tennent's Super - Strong Lager",
            description: "Birra 33cl",
          },
          {
            price: "€1.50",
            name: "Angelo Poretti - Lager",
            description: "Birra 33cl",
          },
          {
            price: "€1.60",
            name: "Becks - Original",
            description: "Birra 33cl",
          },
          {
            price: "€2.20",
            name: "Bjorne Beer",
            description: "Birra 33cl",
          },
          {
            price: "€1.80",
            name: "Heineken",
            description: "Birra 33cl",
          },
          {
            price: "€1.50",
            name: "Peroni",
            description: "Birra 33cl",
          },
          {
            price: "€2.50",
            name: "Ichnusa - Non Filtrata",
            description: "Birra 33cl",
          },
          {
            price: "€1.80",
            name: "Peroni - Chill Lemon",
            description: "Birra 33cl",
          },
          {
            price: "€1.80",
            name: "Birra Moretti - La Rossa",
            description: "Birra 33cl",
          },
          {
            price: "€2.50",
            name: "Ceres - Strong Ale",
            description: "Birra 33cl",
          },
          {
            price: "€2.50",
            name: "86 Original - Special Blonde Beer",
            description: "Birra 500ml",
          },
          {
            price: "€1.80",
            name: "Ichnusa - Anima Sarda",
            description: "Birra 33cl",
          },
          {
            price: "€2.50",
            name: "Maschio",
            description: "Prosecco 500ml",
          },
          {
            price: "€1.80",
            name: "CocaCola Light",
            description: "500ml",
          },
          {
            price: "€2.00",
            name: "Crodino Twist",
            description: "17.5cl",
          },
          {
            price: "€0.60",
            name: "Succo Multivitaminico",
            description: "200ml",
          },
          {
            price: "€1.20",
            name: "Succo al Mirtillo",
            description: "125ml",
          },
          {
            price: "€1.20",
            name: "Fanta",
            description: "Lattina 33cl",
          },
          {
            price: "€1.20",
            name: "CocaCola",
            description: "Lattina 33cl",
          },
          {
            price: "€2.30",
            name: "Monster Energy",
            description: "Lattina 33cl",
          },
          {
            price: "€1.50",
            name: "The Pesca - San Benedetto",
            description: "500ml",
          },
          {
            price: "€1.50",
            name: "The Limone - San Benedetto",
            description: "500ml",
          },
          {
            price: "€1.50",
            name: "Energade - Gusto Limone",
            description: "500ml",
          },
          {
            price: "€0.50",
            name: "Acqua Frizzante - Grazia",
            description: "500ml",
          },
          {
            price: "€0.50",
            name: "Acqua Naturale - San Benedetto",
            description: "500ml",
          },
          {
            price: "€0.50",
            name: "Acqua Naturale - VIVA",
            description: "500ml",
          },
          {
            price: "€1.00",
            name: "Pepsi",
            description: "Lattina 33cl",
          },
          {
            price: "€1.20",
            name: "Tonica Schweppes",
            description: "Lattina 33cl",
          },
          {
            price: "€1.20",
            name: "CocaCola - senza zuccheri",
            description: "Lattina 33cl",
          },
          {
            price: "€1.20",
            name: "Sprite",
            description: "Lattina 33cl",
          },
          {
            price: "€1.00",
            name: "OranSoda - Gusto Arancia",
            description: "Lattina 33cl",
          },
          {
            price: "€1.00",
            name: "LemonSoda - Gusto Limone",
            description: "Lattina 33cl",
          },
          {
            price: "€1.50",
            name: "Burn - Energy Drink",
            description: "Lattina 33cl",
          },
          {
            price: "€1.00",
            name: "The Limone Baby - San Benedetto",
            description: "Lattina 25cl",
          },
          {
            price: "€1.00",
            name: "Aranciata - San Pellegrino",
            description: "Lattina 33cl",
          }
        ]
      },
      {
        id: "pos1",
        name: "H24",
        position: {
          latitude: 42.4045,
          longitude: 12.8567,
          address: "Via Roma, 28, 02100 Rieti RI, Italy"
        },
        items: [
          {
            price: "€1.00",
            name: "Kinder Cards",
            description: "Snack",
          },
          {
            price: "€0.80",
            name: "Kellogs Barretta Cioccolato",
            description: "Barretta",
          },
          {
            price: "€0.80",
            name: "Loacker Tortina Cioccolato",
            description: "Snack",
          },
          {
            price: "€1.20",
            name: "Lion",
            description: "Barretta",
          },
          {
            price: "€0.50",
            name: "Break Choco Caramel",
            description: "Barretta",
          },
          {
            price: "€0.50",
            name: "Oro Ciock",
            description: "Biscotto",
          },
          {
            price: "€1.00",
            name: "Fonzies",
            description: "Snack Salato",
          },
          {
            price: "€1.20",
            name: "Cuor di Crostata - Cranberry",
            description: "Crostatina",
          },
          {
            price: "€1.00",
            name: "Loacker Napolitaner - Nocciola",
            description: "Wafer",
          },
          {
            price: "€1.20",
            name: "Kinder Bueno",
            description: "Snack Dolce",
          },
          {
            price: "€1.00",
            name: "Pan di Stelle - Mooncake",
            description: "Biscotto",
          },
          {
            price: "€0.80",
            name: "Pavesini",
            description: "Biscotto",
          },
          {
            price: "€0.80",
            name: "Le Bon - Barretta Cioccolato Nocciole",
            description: "Snack Dolce",
          },
          {
            price: "€0.80",
            name: "Snack al Rosmarino",
            description: "Snack Salato",
          },
          {
            price: "€0.50",
            name: "Fazzoletti Scottex",
            description: "Altro",
          },
          {
            price: "€1.20",
            name: "KitKat",
            description: "Snack Dolce",
          },
          {
            price: "€0.80",
            name: "Pan di Stelle - Merendina",
            description: "Snack Dolce",
          },
          {
            price: "€1.00",
            name: "Cameo Sticks Salati",
            description: "Snack Salato",
          },
          {
            price: "€1.50",
            name: "Cameo Arachidi",
            description: "Snack Salato",
          },
          {
            price: "€1.00",
            name: "Ringo - Cereali Croccanti con crema al Cocco",
            description: "Biscotto",
          },
          {
            price: "€1.00",
            name: "Ringo - Classici",
            description: "Biscotto",
          },
          {
            price: "€1.00",
            name: "Ringo - Cioccolato",
            description: "Biscotto",
          },
          {
            price: "€0.70",
            name: "Tuc Original",
            description: "Snack Salato",
          },
          {
            price: "€0.50",
            name: "Girella Cioccolato",
            description: "Snack Dolce",
          },
          {
            price: "€0.50",
            name: "Tegolino Cioccolato",
            description: "Snack Dolce",
          },
          {
            price: "€0.50",
            name: "Riso Snack al Cioccolato",
            description: "Snack Dolce",
          },
          {
            price: "-",
            name: "Mentos",
            description: "Altro",
          },
          {
            price: "-",
            name: "Kinder Cereali",
            description: "Snack Dolce",
          },
          {
            price: "-",
            name: "Wafers al Cacao",
            description: "Snack Dolce",
          },
          {
            price: "-",
            name: "Croissant Albicocca",
            description: "Snack Dolce",
          },
          {
            price: "-",
            name: "Croissant Cioccolato",
            description: "Snack Dolce",
          },
          {
            price: "-",
            name: "Ferrero Fiesta",
            description: "Merendina",
          }
        ]
      }
    ];

    this.setState({
      isLoaded: true,
      pointOfSales: pointOfSales,
    })
  }

  setSearchText = (query: string) => {
    this.logSearch(query);
    this.setState({
      searchText: query,
    })
  }

  clearSearchText = () => {
      this.setSearchText("");
      this.searchInput?.focus();
  }

  logSearch = debounce(1000, false, (query: string) => {
    firebase.analytics().logEvent('search', { "query": query })
  });

  getItemsToDisplay(): Array<PointOfSale> {
    const searchText = this.state.searchText.toLowerCase();
    return (this.state.pointOfSales || [])
      .map(pointOfSale => {
        // Clone objects.
        return { ...pointOfSale }
      })
      .map(pointOfSale => {
        if (this.state.currentPosition) {
          pointOfSale.distanceMeters = haversine(this.state.currentPosition.coords, pointOfSale.position, { unit: 'meter' });
        }
        pointOfSale.items = pointOfSale.items
          .filter(item => item.name.toLowerCase().includes(searchText) || item.description.toLowerCase().includes(searchText));
        return pointOfSale;
      })
      .filter(pointOfSale => pointOfSale.items.length !== 0)
      .sort((a, b) => (a.distanceMeters || 0) - (b.distanceMeters || 0));
  }

  tryGetLocation() {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      result.onchange = (_) => this.tryGetLocation();
      // See https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API/Using_the_Permissions_API.
      if (result.state === 'granted') {
        this.getLocation();
      } else if (result.state === 'prompt') {
        // TODO
      } else if (result.state === "denied") {
        // TODO
      }
    })
  }

  getLocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        firebase.analytics().logEvent("location_ok", { "accuracy": position.coords.accuracy });
        this.setState({
          currentPosition: position,
        })
      },
      (error) => {
        console.log(error);
        firebase.analytics().logEvent("location_error", { "code": error.code, "message": error.message });
      },
      {
        enableHighAccuracy: false,
      }
    );
  }

  locationBanner() {
    if (this.state.currentPosition === undefined) {
      return (
        <div className="rounded-md bg-error-500 border-2 border-error-800 text-white p-2 my-2 cursor-pointer" onClick={(_) => this.getLocation()}>
          <FontAwesomeIcon className="mr-2" size="1x" icon={faLocationArrow} />
          <span>missing location -- tap to allow</span>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const pointOfSales = this.getItemsToDisplay();
    const clearSearchButton = (this.state.searchText === "")
      ? null
      : <FontAwesomeIcon
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-primary-500 cursor-pointer"
            size="1x"
            icon={faTimesCircle}
            onClick={(_) => this.clearSearchText()}
            />;
    return (
      <div className="flex justify-center p-2 bg-white">
        <div className="App w-full max-w-lg">
          <a href="https://glassbox.one">
            <div className="text-center text-2xl text-primary-500">
                <img className="w-16 h-16 inline" src="/logo1.svg" alt="logo" />
                <span>GlassBox</span>
                <span className="text-base">.one</span>
            </div>
          </a>
          <div className="text-center text-primary-500 m-2">
            the Vending search engine
            <a href="https://wa.me/message/PUHWPS72QAODE1">
              <FontAwesomeIcon className="text-primary-500 ml-2" size="lg" icon={faWhatsapp} />
            </a>
            <a href="https://www.instagram.com/glassbox.one/">
              <FontAwesomeIcon className="text-primary-500 ml-2" size="lg" icon={faInstagram} />
            </a>
            <a href="mailto:info@glassbox.one">
              <FontAwesomeIcon className="text-primary-500 ml-2" size="lg" icon={faAt} />
            </a>
          </div>
          <div className="relative">
            <FontAwesomeIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-primary-500" size="1x" icon={faSearch} />
            <input
                ref={(i) => {this.searchInput = i || undefined}}
                placeholder="search products near you"
                type="search"
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                autoFocus={true}
                className="border-2 border-primary-500 placeholder-primary-300 rounded-md py-2 pl-10 w-full"
                onChange={(e) => this.setSearchText(e.target.value)}
                value={this.state.searchText}
            >
            </input>
            { clearSearchButton }
          </div>
          {this.locationBanner()}
          {pointOfSales
            .map(pointOfSale => (
              <PointOfSaleView key={pointOfSale.id} pointOfSale={pointOfSale} />
            ))}
          {(pointOfSales.length === 0) ? <span>No results for this query</span> : null }
        </div>
      </div>
    );
  }
}

export default App;
