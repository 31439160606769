import React, { MouseEvent } from 'react';
import { Item, PointOfSale } from './schema';
import firebase from 'firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

export default class SellableItem extends React.Component<{ item: Item, pointOfSale: PointOfSale }> {
    logClick = (target: string) => {
        firebase.analytics().logEvent('click', {
            "pointOfSaleId": this.props.pointOfSale.id,
            "itemName": this.props.item.name,
            "itemPrice": this.props.item.price,
            "target": target,
        })
    }

    render() {
        const item = this.props.item;
        const image = (item.imageUrl === undefined) ?
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" /></svg> :
            <img src={item.imageUrl} />
            ;
        return (
            <article className="p-4 relative flex bg-white"
                onClick={(_) => this.logClick('item_row')} >
                <div onClick={(_) => this.logClick('item_image')}
                    className="w-10" >
                    <FontAwesomeIcon size="lg" icon={faCoffee} />
                </div>
                <div className="w-full px-2">
                    <div className="text-lg font-semibold text-black mb-0.5 inline"
                        onClick={(_) => this.logClick('item_name')}>
                        {item.name}
                    </div>
                    <div className="flex flex-wrap text-sm font-medium whitespace-pre"
                        onClick={(_) => this.logClick('item_description')}>
                        {item.description}
                    </div>
                </div>
                <div className="h-7 align-middle py-0.5 px-2 text-right"
                    onClick={(_) => this.logClick('item_price')}>
                    {item.price}
                </div>
            </article >
        );
    }
}
